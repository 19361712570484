<template>
    <div id="nt_wrapper">

        <Header/>

        <div v-if="single" id="nt_content">

            <!--hero banner-->
            <div class="kalles-section page_section_heading">
                <div class="page-head tc pr oh cat_bg_img page_head_">
                    <div class="parallax-inner nt_parallax_false lazyload nt_bg_lz pa t__0 l__0 r__0 b__0" data-bgset="/img/banner-002.jpeg"></div>
                    <div class="container pr z_100">
                        <h1 class="mb__5 cw">{{single.title}}</h1>
                    </div>
                </div>
            </div>
            <!--end hero banner-->

            <!--page content-->
            <div class="kalles-section container mt__20 mb__60">
                <div class="row fl_center cb">
                    <div class="col-12 txtn mt__25" v-html="single.detail"></div>
                    <div v-if="team_list && team_list.length > 0" class=" col-12 txtn mt__25">
                        <h3 class="fs__20">管理团队</h3>
                    </div>
                    <div v-for="(dt, index) in team_list" class="col-12 col-md-4 mt__30" :key="index">
                        <div class="jas-member tc pr pb__15">
                            <div class="nt_img_ratio lazyload nt_bg_lz padding-top__127_586" :data-bgset="dt.avatar ? dt.avatar : '/images/about-us/mem-01.jpg'"></div>
                            <h4 class="mt__15 fwsb">{{dt.name}}</h4>
                            <span>{{dt.position}}</span>
                            <div v-if="false" class="social pa w__100 ts__03">
                                <a class="facebook" href="https://facebook.com/kalles" target="blank"><i class="lab la-facebook la-facebook-f"></i></a>
                                <a class="twitter" href="https://twitter.com/kalles" target="blank"><i class="lab la-twitter la-twitter-f"></i></a>
                                <a class="dribbble" href="https://dribbble.com/kalles" target="blank"><i class="lab la-dribble la-dribble-f"></i></a>
                                <a class="behance" href="https://behance.net/user" target="blank"><i class="lab la-behance la-behance-f"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end page content-->

        </div>

        <Footer/>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import {getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref} from "vue";
import '/public/css/drift-basic.min.css'
import '/public/css/photoswipe.css'
import {getSingleV1Api} from "@/api/single/single";
import {listTeamV1Api} from "@/api/team/team";

export default {
    name: 'Single',
    components: {Header, Footer},
    setup() {
        const {proxy} = getCurrentInstance();

        const website = inject('website');

        const options = reactive({
            title: '单页',
            name: proxy.$route.name,
            url: proxy.$route.path,
        });

        const single = ref();
        const apiSingle = () => {
            return new Promise(function (resolve) {
                getSingleV1Api(proxy.$route.params.id).then(res => {
                    if (res.data.code == 0) {
                        single.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const team_list = ref([]);
        const apiTeamList = () => {
            return new Promise(function (resolve) {
                listTeamV1Api({page: 1, page_size: 100}).then(res => {
                    if (res.data.code == 0) {
                        team_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        // 初始数据加载
        Promise.all([apiSingle()]).then(() => {
            if (single.value && single.value.single_id == 1) {
                apiTeamList();
            }
        });

        const loadScript = (url) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            document.getElementsByTagName("body")[0].appendChild(script);
        }
        onMounted(() => {
            document.getElementsByTagName('body')[0].className = 'kalles-template single-product-template zoom_tp_2 header_full_true des_header_3 css_scrollbar lazy_icons btnt4_style_2 template-index kalles_toolbar_true hover_img2 swatch_style_rounded swatch_list_size_small label_style_rounded wrapper_full_width hide_scrolld_true lazyload'

            setTimeout(function() {
                loadScript("/js/flickity.pkgd.min.js");
                loadScript("/js/interface.js");
            }, 500);
        })
        onUnmounted(() => {
            document.body.removeAttribute('class')
        })

        return {options, website, single, team_list}
    },
}
</script>

<style scoped>
.swatch_style_rounded .swatch__list .swatch__list--item, .swatch_style_rounded .swatch__list .swatch__value {
    border-radius: 0;
}
</style>